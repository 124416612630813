import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';

const NotFound: React.FC<PageProps> = () => (
  <Layout header="Упс, 404" descr="" showFooter={false}>
    <div>Sorry, page not found!</div>
  </Layout>
);

export default NotFound;
